<template>
<div v-if="visible">
      <Loader v-if="loading" :cols="2" :rows="4"></Loader>
      <div v-else class="p-2 m-2">
            <div class="text-right py-2 pr-2">
                  <b-button @click.prevent.stop="close()" variant="secondary">Stäng</b-button>
            </div>
            <b-container v-for="(statsitem, i) in allstats" :key="i" class="border bg-white my-3 user-tournament-stats-holder">
                  <div class="font-large font-weight-bold py-2">{{statsitem.title}}</div>
                  <b-row class="font-weight-bold border-bottom mb-1 pb-1">
                        <b-col>Typ</b-col>
                        <b-col class="text-right">Poäng</b-col>
                        <b-col class="text-right">%</b-col>
                  </b-row>
                  <b-row v-for="(row, index) in statsitem.rows" :key="index" :class="row.class" class="py-1">
                        <b-col>{{row.key}}</b-col>
                        <b-col class="text-right">{{row.points}}</b-col>
                        <b-col class="text-right">{{row.percentage}}%</b-col>
                  </b-row>
                  <b-row class="border-top font-weight-bold pt-1 mb-2">
                        <b-col>Totalt</b-col>
                        <b-col class="text-right">{{statsitem.totalpoints}}</b-col>
                        <b-col class="text-right">100%</b-col>
                  </b-row>
            </b-container>
      </div>
</div>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Loader = () => import("@/components/miscellaneous/Loader");

export default {
      name: "UserTournamentStats",
      components: {
            Alert,
            Loader
      },
      props: ["username", "tournament"],
      data() {
            return {
                  visible: false,
                  loading: true,
                  fetched: false,
                  stats: {},
                  allstats: []
            };
      },
      computed: {},
      methods: {
            ShowStats() {
                  if (this.fetched) {
                        this.visible = true;
                        this.loading = false;
                  } else {
                        this.visible = true;
                        this.GetStats();
                  }
            },
            GetStats() {
                  this.loading = true;
                  this.$store.dispatch("stats/getUserTournamentStats", {
                        username: this.username,
                        tournamentid: this.tournament.id
                  }).then((response) => {
                        this.stats = response;
                        this.SetTotalStats();
                        this.SetGroupstats();
                        this.loading = false;
                        this.fetched = true;
                  });
            },
            SetTotalStats() {
                  var total = this.stats.total;
                  var statslist = [];
                  this.addToList(statslist, "Resultat", "", total.resultpoints, total.totalpoints);
                  this.addToList(statslist, "Hemma", "font-smaller font-italic text-secondary", total.homepoints, total.resultpoints);
                  this.addToList(statslist, "Borta", "font-smaller font-italic text-secondary", total.awaypoints, total.resultpoints);
                  this.addToList(statslist, "1-X-2", "", total.betpoints, total.totalpoints);
                  this.addToList(statslist, "Bonus", "", total.bonuspoints, total.totalpoints);
                  this.addToList(statslist, "Frågor", "", total.questionpoints, total.totalpoints);
                  this.allstats.push({
                        title: "Totalt " + this.tournament.name,
                        rows: statslist,
                        totalpoints: total.totalpoints
                  })
            },
            SetGroupstats() {
                  var grouptips = this.stats.grouptips;
                  var tglist = [];
                  for (var i = 0; i < grouptips.length; i++) {
                        var g = grouptips[i];
                        var glist = [];
                        this.addToList(glist, "Resultat", "", g.resultpoints, g.totalpoints);
                        this.addToList(glist, "Hemma", "font-smaller font-italic text-secondary", g.homepoints, g.resultpoints);
                        this.addToList(glist, "Borta", "font-smaller font-italic text-secondary", g.awaypoints, g.resultpoints);
                        this.addToList(glist, "1-X-2", "", g.betpoints, g.totalpoints);
                        this.addToList(glist, "Bonus", "", g.bonuspoints, g.totalpoints);
                        this.addToList(glist, "Frågor", "", g.questionpoints, g.totalpoints);
                        this.allstats.push({
                              title: g.groupname,
                              rows: glist,
                              totalpoints: g.totalpoints
                        });

                        this.addToList(tglist, g.groupname, "", g.totalpoints, this.stats.total.totalpoints);
                  }

                  this.allstats.push({
                        title: "Gruppsammanfattning " + this.tournament.name,
                        rows: tglist,
                        totalpoints: this.stats.total.totalpoints
                  });

            },
            addToList(list, key, classname, points, total) {
                  list.push({
                        class: classname,
                        key: key,
                        points: points,
                        percentage: this.percentage(points, total)
                  });
            },
            percentage(value, total) {
                  if (total <= 0)
                        return 0;
                  var percentage = (value / total) * 100;
                  return this.$formatter.round(percentage, 1);
            },
            GetTournamentId() {
                  return this.tournament.id;
            },
            close(){
                  this.visible = false;
            }

      },
      mounted() {},
};
</script>
